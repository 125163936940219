import { z } from 'zod'

export type SiteFeatures = z.infer<typeof SiteFeaturesSchema>

export const SiteFeaturesSchema = z.object({
  enableNoPermitInDisabledSlotOffenseFee: z.boolean().optional(),
  enableMarkedSlotOffenseFee: z.boolean().optional(),
  enableQuickStopOffenseFee: z.boolean().optional(),
  enableFossilCarInEVSlotOffenseFee: z.boolean().optional()
})

export type SiteOffenseFees = z.infer<typeof SiteOffenseFeesSchema>

export const SiteOffenseFeesSchema = z.object({
  maxTotalSessionParkingCost: z.number().optional(),
  maxTotalDailyParkingCost: z.number().optional(),
  noPermitInDisabledSlotOffenseMaxFee: z.number().optional(),
  markedSlotOffenseMaxFee: z.number().optional(),
  fossilCarInEVSlotOffenseMaxFee: z.number().optional(),
  quickStopOffenseMaxFee: z.number().optional()
})
