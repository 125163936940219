import invariant from 'invariant'

import { ONE_SECOND } from '@consts/DateConsts'

import { delay } from '@pure/libs/Common'

import { DEFAULT_SIGN_IN_STATE } from '@my-drifter/screens/SignInScreen'
import { SignInState, SignInSteps } from '@my-drifter/types/SignInTypes'

import { ServicesType } from './SignInMachineHelper'

export const onPressContinue = (signInState: SignInState, s: ServicesType): Promise<SignInState> =>
  Promise.resolve().then(() => {
    switch (signInState.step) {
      case SignInSteps.LANDING:
        return s.startSignInWithCode(signInState)

      case SignInSteps.ENTER_CODE: {
        const { code, phone } = signInState.data

        invariant(code, '!code')
        invariant(signInState.data.confirmationResult, '!signInState.data.confirmationResult')
        invariant(phone, '!phone')

        const { confirmationResult } = signInState.data

        return confirmationResult
          .confirm(code)
          .then(() => delay(ONE_SECOND))
          .then(() => Promise.resolve(DEFAULT_SIGN_IN_STATE))
      }

      default:
        throw new Error(`Unknown step: ${signInState.step}`)
    }
  })
