export const LBE_ENABLED_SITE_IDS: string[] = []

export const HIKER_ENABLED_SITE_IDS = [
  '7985', // Stig Hedlund Fastigheter AB, Mölndalsvägen 95, Göteborg
  '7986', // Hemsö Hissen AB, Folkungavägen 1, Nyköping
  '7989', // Kungälvsbostäder
  // '7992', // Bergsala - Marios Gata 23 (Disabled)
  '7997', // ICA Borås
  '7999', // Råberget Hönekullevägen
  '8001', // Järngrinden Hönä Kullavägen
  '8003', // Nivika Handelsfastigheter AB
  '8004', // Pulsen Fastigheter AB
  '8005', // Nyfosa - Samuel 1 Fastighet AB
  '8006', // Hemköp Redegatan, framsidan
  '8007', // Pulsen Bergshamra
  '8009', // Hemköp Redegatan, baksidan
  '8010', // Nivika baksidan
  '8011', // City Centrum Borås

  '7988', // ICA MAXI Högskolan,
  '7990', // Lidköping real
  '7991', // ICA MAXI Högskolan baksidan
  '7983', // Importgatan
  '7994', // TestSite
  '7995', // Lidköping fake
  '7998', // Skanor
  '8000' // Linnégaraget
  // New boxes
  //'8101' // Importgatan test,
] as string[]
