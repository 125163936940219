import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { GetSessionDataForSiteResponse } from '@contracts/types/GetSessionDataForSiteRequest'
import { HikerUserRole } from '@contracts/types/HikerUser'

import { PermitIncomeByMonthResponse } from '@functions-my-drifter/types/PermitIncomeByMonthForSite'

import { getPermitIncomeByMonthForSite, getRevenueDataForSite } from '@my-drifter/libs/CloudFunctionsApiHandler'

import useAppState from './useAppState'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useUser } from './useUser'

export const FilterTypes = {
  All: 'ALL',
  Parking: 'PARKING',
  Permits: 'PERMITS'
} as const

export type FilterTypes = (typeof FilterTypes)[keyof typeof FilterTypes]

const useRevenueScreenData = () => {
  const { state } = useAppState()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [sessionRevenueData, setSessionRevenueData] = useState<GetSessionDataForSiteResponse>([])
  const [permitRevenueData, setPermitRevenueData] = useState<PermitIncomeByMonthResponse>([])
  const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(dayjs().startOf('year'))
  const [toDate, setToDate] = useState<dayjs.Dayjs | null>(dayjs())
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.All)

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ROOT, HikerUserRole.ADMIN].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)
      if (!selectedSiteId || !fromDate || !toDate) {
        return setIsLoadingData(false)
      }
      try {
        const [sessionRevenueData, permitRevenueData] = await Promise.all([
          getRevenueDataForSite({
            siteId: selectedSiteId,
            startDate: fromDate.startOf('month').format('YYYY-MM-DD'),
            endDate: toDate.endOf('month').format('YYYY-MM-DD')
          }),
          getPermitIncomeByMonthForSite({
            siteId: selectedSiteId,
            fromDate: fromDate.startOf('month').format('YYYY-MM-DD'),
            toDate: toDate.endOf('month').format('YYYY-MM-DD')
          })
        ])
        setSessionRevenueData(sessionRevenueData)
        setPermitRevenueData(permitRevenueData)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData()
  }, [selectedSiteId, fromDate, toDate])

  return {
    isAllowed,
    isLoadingUser,
    isLoadingData,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    selectedSiteId,
    setSelectedSiteId,
    filterType,
    setFilterType,
    sessionRevenueData,
    permitRevenueData
  }
}

export default useRevenueScreenData
