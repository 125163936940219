enum InternalColors {
  colorGray100 = '#D3DEED',
  textWhite = '#FFFFFF',
  OffWhite = '#F0F3FA',
  navigationBlue = '#0A004A',
  navigationPressed = '#2300A3',
  purple900 = '#130068',
  infographicsBrand = '#6D63F1',
  grey600 = '#546378',
  textActionTertiary = '#5347D6',
  paginationHovered = '#C4C0FF',
  gray = '#64748B',
  colorTransparencyLightest = '#0A004A08',
  colorTransparencyLight = '#0A004A16'
}

export const PortalColors = {
  topBorderBackground: InternalColors.colorGray100,
  buttonHovered: InternalColors.colorGray100,
  textWhite: InternalColors.OffWhite,
  textSelectedWhite: InternalColors.textWhite,
  navigationBlue: InternalColors.navigationBlue,
  navigationPressed: InternalColors.navigationPressed,
  navigationHovered: InternalColors.purple900,
  infographicsBrand: InternalColors.infographicsBrand,
  surfaceLevelSecond: InternalColors.OffWhite,
  surfaceActionPrimary: InternalColors.infographicsBrand,
  surfaceActionTertiary: InternalColors.paginationHovered,
  textPrimary: InternalColors.purple900,
  textSecondary: InternalColors.grey600,
  textTertiary: InternalColors.textActionTertiary,
  elevationShadowPrimary: InternalColors.colorTransparencyLightest,
  elevationShadowSecondary: InternalColors.colorTransparencyLight
} as const

export type PortalColors = (typeof PortalColors)[keyof typeof PortalColors]
