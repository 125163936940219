import React, { useEffect } from 'react'

import { onAuthStateChanged } from '@firebase/auth'

import { auth } from '@my-drifter/libs/FirebaseOptions'

import useAppState from './useAppState'

interface FirebaseAuthUserContextInterface {
  authUserId?: string
  setAuthUserId: (id: string) => void
}

const DEFAULT_AUTH_USER_CONTEXT = {
  setAuthUserId: (id: string): void => {}
}

const AuthUserContext = React.createContext<FirebaseAuthUserContextInterface>(DEFAULT_AUTH_USER_CONTEXT)

export function FirebaseAuthUserProvider({ children }) {
  const [authUserId, setAuthUserId] = React.useState<string>()
  const { setState } = useAppState()

  useEffect(() => {
    const unsubscribeFromAuthChanges = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        setAuthUserId(undefined)
        setState((state) => ({ ...state, userId: undefined, firebaseUser: undefined }))

        return
      }

      firebaseUser.getIdTokenResult().then((idTokenResult) => {
        const userId = String(idTokenResult.claims.lbeUserId)

        setAuthUserId(userId)
        setState((state) => ({ ...state, userId, firebaseUser }))
        localStorage.setItem('userId', userId)
      })
    })

    return () => unsubscribeFromAuthChanges()
  }, [])

  return <AuthUserContext.Provider value={{ authUserId, setAuthUserId }}>{children}</AuthUserContext.Provider>
}

export function useAuthUserContext() {
  const context = React.useContext(AuthUserContext)
  if (!context) {
    throw new Error('useAuthUserContext must be used within a FirebaseAuthUserProvider')
  }
  return context
}
