import styled from '@emotion/styled'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { memo } from 'react'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import { FIVE_MINUTES_AGO } from '@consts/DateConsts'

import { HikerUserRole } from '@contracts/types/HikerUser'

import {
  Bergshamra8007,
  Hogskolan7988,
  Honekullavagen8001,
  IcaCityBoras8011,
  ImportGatan7983,
  Kongahalla7989,
  Linne8000,
  Mariosgata7992,
  NivikaJkpg8003,
  PulsenBaras8004,
  Raberget7999,
  Redegatan8006,
  SevenNineNineFive,
  SevenNineNineOne,
  Skanor7998,
  Sockerbruksgatan7990
} from '@my-drifter/assets/siteSvg/SiteSvgs'
import Layout from '@my-drifter/components/Layout'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import ZoomControls from '@my-drifter/components/ZoomControls'
import useAppState from '@my-drifter/hooks/useAppState'
import { useGetActiveSiteCameraEvents } from '@my-drifter/hooks/useGetActiveSiteCameraEvents'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useUser } from '@my-drifter/hooks/useUser'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const DashboardScreen = () => {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const siteSVGs = {
    '7983': ImportGatan7983,
    '7988': Hogskolan7988,
    '7989': Kongahalla7989,
    '7990': Sockerbruksgatan7990,
    '7991': SevenNineNineOne,
    '7992': Mariosgata7992,
    '7995': SevenNineNineFive,
    '7998': Skanor7998,
    '7999': Raberget7999,
    '8000': Linne8000,
    '8001': Honekullavagen8001,
    '8003': NivikaJkpg8003,
    '8004': PulsenBaras8004,
    '8006': Redegatan8006,
    '8007': Bergshamra8007,
    '8009': Redegatan8006, // Redegatan is split into two sites
    '8010': NivikaJkpg8003, // Nivika Jönköping is split into two sites
    '8011': IcaCityBoras8011
  }

  const firstSiteSvg = user?.siteIds ? user.siteIds[0] : Object.keys(siteSVGs)[0]

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  const { data: activeSiteCameraEvents, isLoading: isLoadingActiveSiteCameraEvents } = useGetActiveSiteCameraEvents(
    selectedSiteId ? selectedSiteId : firstSiteSvg
  )

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return (
    <Layout
      isDashboardScreen
      siteSVGs={siteSVGs}
      selectedSiteId={selectedSiteId ? selectedSiteId : firstSiteSvg}
      setSelectedSiteId={setSelectedSiteId}
      isLoading={isLoadingUser}
    >
      <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
        <Box
          sx={{
            padding: PortalSpacings.x6,
            background: PortalColors.surfaceLevelSecond,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0} wheel={{ step: 0.2 }}>
            <TransformComponent>
              <StyledSvgLoader path={siteSVGs[selectedSiteId ? selectedSiteId : firstSiteSvg]}>
                {activeSiteCameraEvents &&
                  !isLoadingActiveSiteCameraEvents &&
                  Object.keys(activeSiteCameraEvents).map((siteCameraEvent) => {
                    const { place_code, record_time } = activeSiteCameraEvents[siteCameraEvent]

                    // record_time is stored in a unix timestamp, so we need to convert it to a dayjs object
                    const recordTime = dayjs.unix(record_time.seconds)

                    if (recordTime.isAfter(FIVE_MINUTES_AGO)) {
                      return (
                        <SvgProxy
                          key={`${place_code}_parked-slot`}
                          selector={`#${place_code}_parked-slot`}
                          visibility="visible"
                        ></SvgProxy>
                      )
                    }
                  })}
              </StyledSvgLoader>
            </TransformComponent>
            <ZoomControls />
          </TransformWrapper>
        </Box>
        {/* <Box sx={{ width: '300px', background: 'green' }}></Box> */}
      </div>
    </Layout>
  )
}

const StyledSvgLoader = styled(SvgLoader)`
  max-width: 80vw;
  max-height: 80vh;
  width: 100%;
  height: 100%;
  align-self: center;
  margin-bottom: ${Spacings.xxxl};
`

export default memo(DashboardScreen)
