import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { blue, grey } from '@mui/material/colors'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import dayjs from 'dayjs'
import { memo, useState } from 'react'

import { YYYY_MM_DD } from '@consts/DateConsts'

import { HikerUserRole } from '@contracts/types/HikerUser'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import DatePicker from '@my-drifter/components/DatePicker'
import FigmaText from '@my-drifter/components/FigmaText'
import Layout from '@my-drifter/components/Layout'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useUser } from '@my-drifter/hooks/useUser'
import { getLinkToGuestParkingFeauture } from '@my-drifter/libs/CloudFunctionsApiHandler'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const Textarea = styled(TextareaAutosize)`
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${grey[900]};
  border: 1px solid ${grey[400]};
  box-shadow: 0px 2px 2px ${grey[50]};
  flex: 1;
  max-width: 640px;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`

const DEFAULT_EXPIRY = dayjs().add(1, 'year').startOf('year')

const StaticQRMakerScreen = () => {
  const { state } = useAppState()

  const [isLoading, setIsLoading] = useState(false)
  const [link, setLink] = useState('')
  const [expiryDate, setExpiryDate] = useState(DEFAULT_EXPIRY)

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  const getLink = async (siteId = selectedSiteId, expiresAt = expiryDate) => {
    setIsLoading(true)

    try {
      if (!siteId) {
        alert('Please select a site')

        return
      }

      const { url: link = '' } = await getLinkToGuestParkingFeauture({
        siteId,
        expiresAt: expiresAt.format(YYYY_MM_DD)
      })

      setLink(link)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const isValidURL = (link: string) => {
    try {
      new URL(link)
    } catch (e) {
      return false
    }

    return true
  }

  const getJWTPayload = (jwt: string) => {
    if (!jwt) return null

    try {
      return JSON.parse(atob(jwt.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  const jwt = isValidURL(link) ? new URL(link).searchParams.get('freeGuestParkingToken') : null
  const payload = jwt ? getJWTPayload(jwt) : null

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)

        getLink(id)
      }}
      isLoading={isLoadingUser}
    >
      <Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: 2, backgroundColor: 'white', m: 2, mt: 0, p: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 640 }}>
          <DatePicker
            format={YYYY_MM_DD}
            value={expiryDate}
            label="Expiry Date"
            onChange={(value) => {
              setExpiryDate(value ?? DEFAULT_EXPIRY)
              getLink(selectedSiteId, value ?? DEFAULT_EXPIRY)
            }}
          />
        </Box>
        <Box sx={{ width: '100%', mt: 4 }}>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Textarea placeholder="https://..." minRows={6} value={link} onChange={(e) => setLink(e.target.value)} />
            <Box>
              <FigmaText textKey={TextsExtended.qrCodeUrlOverviewHeader} />

              <Box
                component={'ul'}
                sx={{ fontFamily: 'poppins', fontSize: 14, pl: 0, listStylePosition: 'inside', listStyleType: 'none' }}
              >
                <li>
                  <span style={{ fontWeight: 500 }}>Site:</span> {payload?.siteId ?? 'N/A'}
                </li>
                <li>
                  <span style={{ fontWeight: 500 }}>Expires At:</span> {payload?.date ?? 'N/A'}
                </li>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" disabled={isLoading} onClick={() => getLink()}>
              Get Link
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                navigator.clipboard
                  .writeText(link)
                  .then((value) => {
                    alert(`Link copied to clipboard!`)
                  })
                  .catch((err) => {
                    console.error('Failed to copy text: ', err)
                  })
              }
            >
              Copy Link
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default memo(StaticQRMakerScreen)
