import styled from '@emotion/styled'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Box as MuiBox, FormControl, MenuItem, Select, SvgIcon } from '@mui/material'
import dayjs from 'dayjs'
import { memo } from 'react'

import { sortDates } from '@pure/libs/DayJsHelper'
import { CUSTOMER_PERMITS_PERCENTAGE, groupAndAggregatePaymentsByMonth } from '@pure/libs/financialHelpers'

import { PermitIncomeByMonthResponse } from '@functions-my-drifter/types/PermitIncomeByMonthForSite'

import PortalTexts from '@my-drifter/assets/texts_extended.json'
import Box from '@my-drifter/components/Box'
import FigmaImage from '@my-drifter/components/FigmaImage'
import FigmaText from '@my-drifter/components/FigmaText'
import Layout from '@my-drifter/components/Layout'
import MonthPicker from '@my-drifter/components/MonthPicker'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import RevenueTable, { RevenueData } from '@my-drifter/components/RevenueTable'
import useRevenueScreenData, { FilterTypes } from '@my-drifter/hooks/useRevenueScreenData'
import Images from '@my-drifter/libs/Images'
import { getFigmaText } from '@my-drifter/libs/TextRepository'

import NotAuthorizedScreen from './NotAuthorizedScreen'

function mergePermitRevenueWithSessionRevenue(
  sessionRevenueByMonth: Record<string, RevenueData>,
  permitRevenueData: PermitIncomeByMonthResponse
) {
  const merged = permitRevenueData.reduce<Record<string, RevenueData>>((acc, curr) => {
    if (!acc[curr.month]) {
      const date = dayjs(curr.month)
      acc[curr.month] = {
        month: curr.month,
        date,
        generated: 0,
        paid: 0,
        outstanding: 0
      }
    }

    const total = acc[curr.month]
    if (total) {
      total.generated += curr.paid * CUSTOMER_PERMITS_PERCENTAGE + curr.outstanding * CUSTOMER_PERMITS_PERCENTAGE
      total.paid += curr.paid * CUSTOMER_PERMITS_PERCENTAGE
      total.outstanding += curr.outstanding * CUSTOMER_PERMITS_PERCENTAGE
    }
    return acc
  }, sessionRevenueByMonth)
  return Object.values(merged)
}

const RevenueScreen = () => {
  const {
    isAllowed,
    isLoadingUser,
    isLoadingData,
    sessionRevenueData,
    permitRevenueData,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    selectedSiteId,
    setSelectedSiteId,
    filterType,
    setFilterType
  } = useRevenueScreenData()

  const sessionRevenueByMonth = groupAndAggregatePaymentsByMonth(sessionRevenueData)

  let totalRevenueByMonth: RevenueData[] = []
  if (filterType === FilterTypes.All) {
    totalRevenueByMonth = mergePermitRevenueWithSessionRevenue(sessionRevenueByMonth, permitRevenueData)
      .sort((a, b) => sortDates(a.date, b.date))
      .reverse()
  } else if (filterType === FilterTypes.Parking) {
    totalRevenueByMonth = Object.values(sessionRevenueByMonth)
      .sort((a, b) => sortDates(a.date, b.date))
      .reverse()
  } else if (filterType === FilterTypes.Permits) {
    totalRevenueByMonth = mergePermitRevenueWithSessionRevenue({}, permitRevenueData)
      .sort((a, b) => sortDates(a.date, b.date))
      .reverse()
  }

  const filterTypeToLocalizedText = (value: string) => {
    switch (value) {
      default:
      case FilterTypes.All:
        return getFigmaText(PortalTexts.revenueReportrevenueBreakdownincomeTypes)
      case FilterTypes.Parking:
        return getFigmaText(PortalTexts.revenueReportdataVisualisationgraphKeyParking)
      case FilterTypes.Permits:
        return getFigmaText(PortalTexts.revenueReportdataVisualisationgraphKeyPermits)
    }
  }

  const now = dayjs()
  const lastMonth = now.subtract(1, 'month')

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  const fromLabel = getFigmaText(PortalTexts.MonthPickerFromLabel)
  const toLabel = getFigmaText(PortalTexts.MonthPickerToLabel)

  return (
    <Layout selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} isLoading={isLoadingUser}>
      <Box gap={PortalSpacings.x6} spacing={PortalSpacings.x6} fullPadding>
        <TitleBox direction="row" align="center" gap={PortalSpacings.x3}>
          <FigmaImage imageKey={Images.paymentsDark} />
          <FigmaText textKey={PortalTexts.revenueReportheaderheadlineMain} />
        </TitleBox>
        <SubtitleBox>
          <FigmaText textKey={PortalTexts.revenueReportheaderheadlineDescription} />
        </SubtitleBox>
      </Box>
      <Box fullWidth align="center" backgroundColor="#FFF" flexGrow={1}>
        <Box alignSelf="flex-start" direction="row" spacing={PortalSpacings.x6} top left gap={PortalSpacings.x2}>
          <MonthPicker label={fromLabel} date={fromDate} maxDate={lastMonth} setDate={setFromDate} />
          <MonthPicker label={toLabel} date={toDate} maxDate={now} setDate={setToDate} />
          <FormControl sx={{ m: 1, minWidth: '202px', margin: 0 }} size="small">
            <Select
              sx={{ '#filter-type-select': { paddingRight: PortalSpacings.x3 } }}
              style={whiteBackgroundStyle}
              id="filter-type-select"
              value={filterType}
              IconComponent={() => null}
              renderValue={(value) => {
                return (
                  <MuiBox
                    sx={{
                      alignSelf: 'stretch',
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-around',
                      gap: PortalSpacings.x1
                    }}
                  >
                    <MuiBox sx={{ flexGrow: 1 }}>{filterTypeToLocalizedText(value)}</MuiBox>
                    <MuiBox>
                      <SvgIcon sx={{ display: 'flex' }} color="primary">
                        <FilterListIcon />
                      </SvgIcon>
                    </MuiBox>
                  </MuiBox>
                )
              }}
              onChange={(e) => {
                setFilterType((e.target.value as FilterTypes) || FilterTypes.All)
              }}
            >
              <MenuItem style={whiteBackgroundStyle} value={FilterTypes.All}>
                {getFigmaText(PortalTexts.revenueReportrevenueBreakdownincomeTypes)}
              </MenuItem>
              <MenuItem style={whiteBackgroundStyle} value={FilterTypes.Parking}>
                {getFigmaText(PortalTexts.revenueReportdataVisualisationgraphKeyParking)}
              </MenuItem>
              <MenuItem style={whiteBackgroundStyle} value={FilterTypes.Permits}>
                {getFigmaText(PortalTexts.revenueReportdataVisualisationgraphKeyPermits)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box direction="row" spacing={PortalSpacings.x6} top left gap={PortalSpacings.x2}></Box>

        <RevenueTable data={totalRevenueByMonth} loading={isLoadingData} />
      </Box>
    </Layout>
  )
}

const whiteBackgroundStyle = {
  fontSize: '1rem',
  backgroundColor: PortalColors.textSelectedWhite
}

const TitleBox = styled(Box)`
  color: ${PortalColors.textPrimary};
`

const SubtitleBox = styled(Box)`
  color: ${PortalColors.textSecondary};
`

export default memo(RevenueScreen)
