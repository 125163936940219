import { collection, collectionGroup, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { HikerUser } from '@contracts/types/HikerUser'
import { Permit, PermitUserDeletion, PermitUserDeletionRequest } from '@contracts/types/Permit'

import { getDocData } from '@pure/libs/CommonDBApiHandlerHelper'

import { db } from './FirebaseOptions'

export const upsertPermit = async (
  siteId: string,
  permitDebtorId: string,
  permitId: string,
  updatedPermit: Permit
): Promise<Permit> =>
  setDoc(
    doc(
      db,
      `${Collections.SITES}/${siteId}/${CollectionGroups.PERMIT_DEBTORS}/${permitDebtorId}/${CollectionGroups.PERMITS}/${permitId}`
    ),
    updatedPermit
  ).then(() => updatedPermit)

export const getUserByPhoneNumber = async (phoneNumber?: string | null) => {
  const result = await getDocs(query(collection(db, Collections.USERS), where('phone', '==', phoneNumber)))
  return (await getDocData(result.docs[0])) as HikerUser
}

export const getPermitById = async (permitId: string) => {
  const permitQuery = await getDocs(collectionGroup(db, CollectionGroups.PERMITS))
  const permits = permitQuery.docs.map((doc) => doc.data() as Permit)

  return permits.find((permit) => permit.id === permitId)
}

export const deletePermitUserDeletion = async (permitUserDeletetion: PermitUserDeletion | PermitUserDeletionRequest) =>
  deleteDoc(
    doc(db, `${Collections.PERMIT_USER_DELETION}/${permitUserDeletetion.userId}_${permitUserDeletetion.permitId}`)
  )
