import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import invariant from 'invariant'

import { formatPayerAlias } from '@web-js/libs/PhoneNumberHelper'

import { auth } from '@my-drifter/libs/FirebaseOptions'
import { SignInState, SignInSteps } from '@my-drifter/types/SignInTypes'

export const SIGN_IN_BUTTON_ID = 'sign-in-button'

export const startSignInWithCode = (signInState: SignInState): Promise<SignInState> => {
  const w = window as any
  w.recaptchaVerifier = new RecaptchaVerifier(auth, SIGN_IN_BUTTON_ID, {
    size: 'invisible'
  })

  const { phone } = signInState.data
  invariant(phone, '!phone')

  // TODO WRITE TEST, should be +46708834332 for 0708834332
  const _phone = `+${formatPayerAlias(phone)}`

  return signInWithPhoneNumber(auth, _phone, w.recaptchaVerifier).then((confirmationResult) => ({
    ...signInState,
    step: SignInSteps.ENTER_CODE,
    data: { ...signInState.data, confirmationResult }
  }))
}
