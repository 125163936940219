import ImportGatan7983Svg from './importgatan_7983.svg'
export const ImportGatan7983 = ImportGatan7983Svg
import Hogskolan7988Svg from './7988_hogskolan.svg'
export const Hogskolan7988 = Hogskolan7988Svg
import Kongahalla7989Svg from './7989_kongahalla.svg'
export const Kongahalla7989 = Kongahalla7989Svg
import Sockerbruksgatan7990Svg from './7990_sockerbruksgatan.svg'
export const Sockerbruksgatan7990 = Sockerbruksgatan7990Svg
import SevenNineNineOneSvg from './7991.svg'
export const SevenNineNineOne = SevenNineNineOneSvg
import Mariosgata7992Svg from './7992_mariosgata.svg'
export const Mariosgata7992 = Mariosgata7992Svg
import SevenNineNineFiveSvg from './7995.svg'
export const SevenNineNineFive = SevenNineNineFiveSvg
import Skanor7998Svg from './7998_skanor.svg'
export const Skanor7998 = Skanor7998Svg
import Raberget7999Svg from './7999_raberget.svg'
export const Raberget7999 = Raberget7999Svg
import Linne8000Svg from './8000_linne.svg'
export const Linne8000 = Linne8000Svg
import Honekullavagen8001Svg from './8001_hokekullagen.svg'
export const Honekullavagen8001 = Honekullavagen8001Svg
import NivikaJkpg8003Svg from './8003_nivikajkpg.svg'
export const NivikaJkpg8003 = NivikaJkpg8003Svg
import PulsenBaras8004Svg from './8004_pulsen_boras.svg'
export const PulsenBaras8004 = PulsenBaras8004Svg
import Redegatan8006Svg from './8006_hemkop_redegatan.svg'
export const Redegatan8006 = Redegatan8006Svg
import Bergshamra8007Svg from './8007_pulsen_bergshamra.svg'
export const Bergshamra8007 = Bergshamra8007Svg
import IcaCityBoras8011Svg from './8011_ica_city_boras.svg'
export const IcaCityBoras8011 = IcaCityBoras8011Svg
